import React, { useState, useEffect } from 'react';
import { useBackendAuth } from '../contexts/backendAuth';
import { useLocation } from 'react-router-dom';

const AuthModal = () => {
  const { login, isAuthenticated, showAuthModal, setShowAuthModal } = useBackendAuth();
  const location = useLocation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await login(username, password);
    } catch (err) {
      setError('Invalid username or password');
    }
  };

  useEffect(() => {
    if (location.pathname === '/backoffice') {
      setShowAuthModal(true);
    } else {
      setShowAuthModal(false);
    }
  }, [location, setShowAuthModal]);

  useEffect(() => {
    if (isAuthenticated && location.pathname === '/backoffice') {
      setShowAuthModal(false);
    }
  }, [isAuthenticated, location, setShowAuthModal]);

  if (!showAuthModal || location.pathname !== '/backoffice') return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="bg-white p-5 rounded-lg shadow-lg">
        <h2 className="text-xl mb-4">Login Required</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="username" className="block mb-2">Username</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block mb-2">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default AuthModal;
