import React, { useState, useMemo, useEffect, useContext } from 'react';
import { EmbedContext } from '../App';
import { FaChevronDown, FaChevronUp, FaFileAlt, FaPrint, FaGoogle, FaPhone, FaEnvelope, FaGlobe, FaBaby, FaTrash } from 'react-icons/fa';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useBackendAuth } from '../contexts/backendAuth';
import axios from 'axios';

const decodeFacilityName = (name) => {
  const germanCharMap = {
    '\u00e4': 'ä', '\u00f6': 'ö', '\u00fc': 'ü',
    '\u00c4': 'Ä', '\u00d6': 'Ö', '\u00dc': 'Ü',
    '\u00df': 'ß'
  };
  return name.replace(/\\u[\dA-F]{4}/gi, (match) => {
    const decoded = String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
    return germanCharMap[decoded] || decoded;
  });
};


const ConfirmModal = ({ isOpen, onClose, onConfirm, isDeleting }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl">
        <h2 className="text-xl font-bold mb-4">Einrichtung entfernen</h2>
        <p className="mb-4">Sind Sie sicher, dass Sie diese Einrichtung entfernen möchten?</p>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="mr-2 px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
            disabled={isDeleting}
          >
            Abbrechen
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 flex items-center"
            disabled={isDeleting}
          >
            {isDeleting ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Wird entfernt...
              </>
            ) : (
              'Entfernen'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

const FacilityList = ({ facilities, onRemoveFacility, setFacilities, orderId }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [pdfUrls, setPdfUrls] = useState({});
  const [loadingPdfs, setLoadingPdfs] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [facilityToRemove, setFacilityToRemove] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const openModal = (facility) => {
    setFacilityToRemove(facility);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setFacilityToRemove(null);
    setModalIsOpen(false);
    setIsDeleting(false);
  };

  const handleRemove = async () => {
    if (facilityToRemove) {
      setIsDeleting(true);
      try {
        await onRemoveFacility(facilityToRemove, orderId);
        setFacilities(prevFacilities => prevFacilities.filter(f => f.slug !== facilityToRemove.slug));
      } catch (error) {
        console.error('Error removing facility:', error);
        // Handle error (e.g., show an error message to the user)
      } finally {
        setIsDeleting(false);
        closeModal();
      }
    }
  };

  const fetchPdf = async (applicationId) => {
    setLoadingPdfs(prev => ({ ...prev, [applicationId]: true }));
    try {
      const token = localStorage.getItem('token');
      const api = axios.create({
        baseURL: process.env.REACT_APP_DEV_MODE === 'true' ? 'http://localhost:8000' : 'https://backend.app.kita.kids',
        withCredentials: true,
        responseType: 'blob',
      });

      const response = await api.get(`/backoffice/applications/${applicationId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const pdfBlob = new Blob([response.data], { type: response.headers['content-type'] });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      
      setPdfUrls(prevUrls => ({ ...prevUrls, [applicationId]: pdfUrl }));
    } catch (error) {
      console.error('Error fetching PDF:', error);
    } finally {
      setLoadingPdfs(prev => ({ ...prev, [applicationId]: false }));
    }
  };

  useEffect(() => {
    if (isExpanded) {
      facilities.forEach(facility => {
        if (facility.application && facility.application.id) {
          fetchPdf(facility.application.id);
        }
      });
    }

    // Cleanup function
    return () => {
      Object.values(pdfUrls).forEach(URL.revokeObjectURL);
      setLoadingPdfs({}); // Reset loading states
    };
  }, [isExpanded, facilities]);

  return (
    <div className="mt-4">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex items-center justify-between w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-200"
      >
        <span className="text-lg font-semibold">
          {isExpanded ? `${facilities.length}. Einrichtungen ausblenden` : `${facilities.length}. Einrichtungen anzeigen`}
        </span>
        {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
      </button>
      {isExpanded && (
        <div className="mt-4 grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {facilities.length > 0 ? (
            facilities.map((facility, facilityIndex) => (
              <div key={facility.id} className={`bg-white rounded-lg shadow-md overflow-hidden ${facility.isPartner ? 'border-2 border-yellow-400' : ''} relative`}>
                <button
                  onClick={() => openModal(facility)}
                  className="absolute top-2 right-2 text-red-500 hover:text-red-700"
                  aria-label="Remove facility"
                >
                  <FaTrash />
                </button>
                <div className="p-4">
                  <h3 className={`text-xl font-semibold mb-2 ${facility.isPartner ? 'text-yellow-600' : 'text-gray-800'}`}>
                    {facilityIndex + 1}. {facility.name} {facility.isPartner && '(Partner)'}
                  </h3>
                  <p className="text-sm text-gray-600 mb-2">{facility.address}, {facility.postcode} {facility.city}</p>
                  <div className="space-y-1">
                    {facility.phone && (
                      <p className="text-sm text-gray-600 flex items-center">
                        <FaPhone className="mr-2 text-blue-500" /> {facility.phone}
                      </p>
                    )}
                    {facility.email && (
                      <p className="text-sm text-gray-600 flex items-center">
                        <FaEnvelope className="mr-2 text-blue-500" /> {facility.email}
                      </p>
                    )}
                    {facility.website && (
                      <p className="text-sm text-gray-600 flex items-center">
                        <FaGlobe className="mr-2 text-blue-500" />
                        <a href={facility.website} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Website</a>
                      </p>
                    )}
                    {facility.mindestalter && (
                      <p className="text-sm text-gray-600 flex items-center">
                        <FaBaby className="mr-2 text-blue-500" /> Mindestalter: {facility.mindestalter}
                      </p>
                    )}
                  </div>
                  {facility.type && facility.type.length > 0 && (
                    <div className="mt-2">
                      <p className="text-sm font-semibold text-gray-700">Typ:</p>
                      <div className="flex flex-wrap gap-1 mt-1">
                        {facility.type.map((t, index) => (
                          <span key={index} className="text-xs bg-gray-200 text-gray-700 px-2 py-1 rounded">
                            {t.value}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                  {facility.application && (
                    <div className="mt-4 p-3 bg-gray-100 rounded-lg">
                      <p className="font-semibold text-gray-700 mb-2">Kennenlernkarte:</p>
                      <p className="text-sm text-gray-600">
                        Versand: {facility.application.shipmentOrdered ? 'Ja' : 'Nein'}
                      </p>
                      <p className="text-sm text-gray-600">
                        Überprüft: {facility.application.Reviewed ? 'Ja' : 'Nein'}
                      </p>
                      {loadingPdfs[facility.application.id] ? (
                        <div className="mt-2 flex items-center text-sm text-blue-500">
                          <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          PDF wird geladen...
                        </div>
                      ) : pdfUrls[facility.application.id] ? (
                        <a 
                          href={pdfUrls[facility.application.id]} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className="mt-2 inline-flex items-center text-sm text-blue-500 hover:text-blue-600"
                        >
                          <FaFileAlt className="mr-1" /> PDF anzeigen
                        </a>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-600 col-span-full">Keine Einrichtungen ausgewählt</p>
          )}
        </div>
      )}
      <ConfirmModal
        isOpen={modalIsOpen}
        onClose={closeModal}
        onConfirm={handleRemove}
        isDeleting={isDeleting}
      />
    </div>
  );
};

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
};

const mockOrders = [
  {
    id: 1,
    order: "1.00000000000000000000",
    paymentStatus: { id: 1, value: "Bezahlt", color: "green" },
    Notes: "Dringende Bearbeitung erforderlich",
    Active: true,
    userName: "John Doe",
    partnerName: "Jane Doe",
    numberChildren: "2",
    childrenName: "Alice, Bob",
    adressStreet: "Hauptstraße 123",
    adressPLZ: "70173",
    adressCity: "Stuttgart",
    phone: "+49-711-123456",
    email: "john.doe@example.com",
    needDate: "2023-09-01",
    distance: 5,
    maxDeviation: 2,
    childcareType: { id: 1, value: "Vollzeit", color: "blue" },
    localAllocationRegulatory: { id: 1, value: "Ja", color: "green" },
    wantedCarrierType: [{ id: 1, value: "Öffentlich", color: "blue" }],
    story: "Suchen eine fürsorgliche Umgebung für unsere Kinder",
    childImage: [{ url: "https://example.com/child1.jpg" }],
    processed_images: [{ url: "https://example.com/processed1.jpg" }],
    relationshipStatus: "Verheiratet",
    child1Name: "Alice",
    child2Name: "Bob",
    child1Birthday: "2019-05-15",
    child2Birthday: "2021-03-20",
    packet: { id: 1, value: "Standard", color: "blue" },
    message: "Bitte berücksichtigen Sie unsere Bewerbung",
    submittedAt: "2023-06-15T10:30:00Z",
    cardText: "Benutzerdefinierter Text für Karten",
    allowImpersonation: { id: 1, value: "Ja", color: "green" },
    paymentId: "PAY001",
    paymentLink: "https://payment.example.com/PAY001",
    zammadID: "ZAM001",
    price: "199.99",
    shopifyOrderNumber: 10001,
    Nuture: true,
    NutureStep: "Erstkontakt",
    "Last modified": "2023-06-20",
    lat: "48.7758",
    lon: "9.1829",
    UUID: "123e4567-e89b-12d3-a456-426614174000",
    facilityIDs: [
      {"slug": "staedt-kita-schoenbuehlstrasse-schoenbuehlstrasse-100", "name": "Städt. Kita Schönbühlstraße", "isPartner": false},
      {"slug": "evangelische-kindertagesstaette-pistoriuspflege-hornbergstrasse-101", "name": "Evangelische Kindertagesstätte Pistoriuspflege", "isPartner": false},
      {"slug": "evangelische-lutherhaus-kindergarten-boslerstrasse-10", "name": "Evangelische Lutherhaus-Kindergarten", "isPartner": false}
    ],
    "Created on": "2023-06-15T10:00:00Z",
    businessPartner: "KitaPartner GmbH",
  },
  {
    id: 2,
    order: "2.00000000000000000000",
    paymentStatus: { id: 2, value: "Nicht bezahlt", color: "red" },
    Notes: "Warten auf Zahlungsbestätigung",
    Active: true,
    userName: "Emma Schmidt",
    partnerName: "Max Schmidt",
    numberChildren: "1",
    childrenName: "Lena",
    adressStreet: "Elbchaussee 456",
    adressPLZ: "22609",
    adressCity: "Hamburg",
    phone: "+49-40-987654",
    email: "emma.schmidt@example.com",
    needDate: "2023-10-01",
    distance: 3,
    maxDeviation: 1,
    childcareType: { id: 2, value: "Teilzeit", color: "yellow" },
    localAllocationRegulatory: { id: 2, value: "Nein", color: "red" },
    wantedCarrierType: [{ id: 2, value: "Privat", color: "green" }],
    story: "Suchen eine Teilzeitbetreuung für unsere Tochter",
    childImage: [{ url: "https://example.com/child2.jpg" }],
    processed_images: [{ url: "https://example.com/processed2.jpg" }],
    relationshipStatus: "Ledig",
    child1Name: "Lena",
    child1Birthday: "2020-11-10",
    packet: { id: 2, value: "Premium", color: "gold" },
    message: "Flexibel beim Startdatum",
    submittedAt: "2023-06-18T14:45:00Z",
    cardText: "Standardtext für Karten",
    allowImpersonation: { id: 2, value: "Nein", color: "red" },
    paymentId: "PAY002",
    paymentLink: "https://payment.example.com/PAY002",
    zammadID: "ZAM002",
    price: "249.99",
    shopifyOrderNumber: 10002,
    Nuture: false,
    NutureStep: "",
    "Last modified": "2023-06-22",
    lat: "53.5511",
    lon: "9.9937",
    UUID: "987f6543-e21b-12d3-a456-426614174000",
    facilityIDs: [
      {"slug": "staedt-kita-schoenbuehlstrasse-schoenbuehlstrasse-100", "name": "Städt. Kita Schönbühlstraße", "isPartner": false},
      {"slug": "evangelische-kindertagesstaette-pistoriuspflege-hornbergstrasse-101", "name": "Evangelische Kindertagesstätte Pistoriuspflege", "isPartner": false},
      {"slug": "evangelische-lutherhaus-kindergarten-boslerstrasse-10", "name": "Evangelische Lutherhaus-Kindergarten", "isPartner": false}
    ],
    "Created on": "2023-06-18T14:30:00Z",
    businessPartner: "HamburgerKitas e.V.",
  },
];

const renderPill = (condition, text) => {
  if (condition) {
    return (
      <span className="ml-2 px-2 py-1 text-xs font-semibold rounded-full bg-red-100 text-red-800">
        {text}
      </span>
    );
  }
  return null;
};

const OrderItem = ({ order, safeAccess, isExpanded, onRemoveFacility, updateFacilities }) => {
  const orderId = safeAccess(order, 'id', '');
  const [isOrderExpanded, setIsOrderExpanded] = useState(isExpanded);
  const [facilities, setFacilities] = useState([]);
  const [isLoadingFacilities, setIsLoadingFacilities] = useState(false);
  const [facilityError, setFacilityError] = useState(null);

  const fetchFacilities = async () => {
    setIsLoadingFacilities(true);
    setFacilityError(null);
    try {
      const token = localStorage.getItem('token');
      const api = axios.create({
        baseURL: process.env.REACT_APP_DEV_MODE === 'true' ? 'http://localhost:8000' : 'https://backend.app.kita.kids',
        withCredentials: true,
      });

      // Fetch facilities
      const facilitiesResponse = await api.post(`/backoffice/${safeAccess(order, 'UUID', '')}/facilities`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      // Fetch applications
      const applicationsResponse = await api.post('/backoffice/applications', {
        order_id: safeAccess(order, 'id', '')
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (facilitiesResponse.data && facilitiesResponse.data.facilities && Array.isArray(facilitiesResponse.data.facilities)) {
        let facilitiesWithApplications = facilitiesResponse.data.facilities.map(facility => ({
          ...facility,
          application: null
        }));

        if (applicationsResponse.data && applicationsResponse.data.results && Array.isArray(applicationsResponse.data.results)) {
          applicationsResponse.data.results.forEach(application => {
            const facilityId = application.facility[0]?.id;
            if (facilityId) {
              const facilityIndex = facilitiesWithApplications.findIndex(f => f.id === facilityId);
              if (facilityIndex !== -1) {
                facilitiesWithApplications[facilityIndex].application = application;
              }
            }
          });
        }

        setFacilities(facilitiesWithApplications);
      } else {
        const receivedDataType = typeof facilitiesResponse.data;
        const receivedDataStructure = facilitiesResponse.data === null ? 'null' : (typeof facilitiesResponse.data === 'object' ? 'Object' : 'Unbekannt');
        const facilitiesType = facilitiesResponse.data && facilitiesResponse.data.facilities ? (Array.isArray(facilitiesResponse.data.facilities) ? 'Array' : typeof facilitiesResponse.data.facilities) : 'Nicht vorhanden';
        setFacilityError(`Ungültiges Datenformat von der API erhalten. Erwartet: Object mit facilities Array, Erhalten: ${receivedDataType} (${receivedDataStructure}), facilities: ${facilitiesType}`);
      }

    } catch (error) {
      console.error('Fehler beim Abrufen der Daten:', error);
      setFacilityError('Fehler beim Laden der Einrichtungen und Bewerbungen. Bitte versuchen Sie es später erneut.');
    } finally {
      setIsLoadingFacilities(false);
    }
  };

  useEffect(() => {
    if (isOrderExpanded && facilities.length === 0 && !isLoadingFacilities) {
      fetchFacilities();
    }
  }, [isOrderExpanded]);

  const startPrintingProcess = () => {
    console.log('Druckprozess wird gestartet für Bestellung', safeAccess(order, 'id', 'Unbekannt'));
  };

  return (
    <div className="bg-white shadow-md rounded-lg mb-4 overflow-hidden">
      {!isExpanded && (
        <div
          className="flex justify-between items-center p-4 cursor-pointer"
          onClick={() => setIsOrderExpanded(!isOrderExpanded)}
        >
          <h3 className="text-lg font-semibold">
            Bestellung #{safeAccess(order, 'id', 'Unbekannt')}
            {renderPill(!safeAccess(order, 'story', ''), "Story fehlt")}
            {renderPill(!safeAccess(order, 'processed_image', []).length, "Bild fehlt")}
            {renderPill(!safeAccess(order, 'facilityIDs', []).length, "Einrichtungen fehlen")}
            {renderPill(!safeAccess(order, 'previewCard', ''), "Vorschaukarte fehlt")}
          </h3>
          <div className="flex items-center">
            {isOrderExpanded ? <FaChevronUp /> : <FaChevronDown />}
          </div>
        </div>
      )}
      {isOrderExpanded && (
        <div className="p-4 border-t">
          <div className="grid grid-cols-2 gap-4 mb-4">
            <p><strong>Benutzername:</strong> {safeAccess(order, 'userName', 'Nicht angegeben')}</p>
            <p><strong>Adresse:</strong> {`${safeAccess(order, 'adressStreet', '')}, ${safeAccess(order, 'adressPLZ', '')} ${safeAccess(order, 'adressCity', '')}`}</p>
            <p><strong>Telefon:</strong> {safeAccess(order, 'phone', 'Nicht angegeben')}</p>
            <p><strong>Geschäftspartner:</strong> {safeAccess(order, 'businessPartner', 'Nicht angegeben')}</p>
          </div>
          <div className="mt-4">
            <strong>Ausgewählte Einrichtungen:</strong>
            {isLoadingFacilities ? (
              <p>Lade Einrichtungen und Kennenlernkarten...</p>
            ) : facilityError ? (
              <p className="text-red-500">{facilityError}</p>
            ) : (
              <FacilityList 
                facilities={facilities} 
                onRemoveFacility={onRemoveFacility}
                setFacilities={(newFacilities) => {
                  setFacilities(newFacilities);
                  updateFacilities(newFacilities);
                }}
                orderId={orderId}
              />
            )}
          </div>
          <div className="flex flex-wrap gap-2 mt-4">
            <button
              onClick={startPrintingProcess}
              className="flex items-center bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600"
            >
              <FaPrint className="mr-2" /> Druckprozess starten
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const StatisticsGraph = ({ orders, startDate, endDate }) => {
  const data = useMemo(() => {
    const filteredOrders = orders.filter(order => {
      const orderDate = new Date(order.submittedAt);
      return orderDate >= new Date(startDate) && orderDate <= new Date(endDate);
    });

    const ordersByDate = filteredOrders.reduce((acc, order) => {
      const date = formatDate(order.submittedAt);
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(ordersByDate).map(([date, count]) => ({
      date,
      Bestellungen: count
    })).sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [orders, startDate, endDate]);

  return (
    <div className="h-64 w-full">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="Bestellungen" stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

const FilterComponent = ({ filters, setFilters }) => {
  return (
    <div className="mb-4">
      <h3 className="text-lg font-semibold mb-2">Filter</h3>
      <div className="flex flex-wrap gap-4">
        <select
          className="p-2 border rounded"
          value={filters.paymentStatus}
          onChange={(e) => setFilters({ ...filters, paymentStatus: e.target.value })}
        >
          <option value="">Alle Zahlungsstatus</option>
          <option value="Bezahlt">Bezahlt</option>
          <option value="Nicht bezahlt">Nicht bezahlt</option>
        </select>
        <select
          className="p-2 border rounded"
          value={filters.packet}
          onChange={(e) => setFilters({ ...filters, packet: e.target.value })}
        >
          <option value="">Alle Pakete</option>
          <option value="Standard">Standard</option>
          <option value="Premium">Premium</option>
        </select>
        <select
          className="p-2 border rounded"
          value={filters.active}
          onChange={(e) => setFilters({ ...filters, active: e.target.value })}
        >
          <option value="">Alle Status</option>
          <option value="true">Aktiv</option>
          <option value="false">Inaktiv</option>
        </select>
      </div>
    </div>
  );
};

const Backoffice = () => {
  const { isAuthenticated } = useBackendAuth();
  const { isEmbedded, embedData } = useContext(EmbedContext);
  const [filters, setFilters] = useState({
    paymentStatus: '',
    packet: '',
    active: '',
  });
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [facilities, setFacilities] = useState([]);

  const handleRemoveFacility = async (facility, orderId) => {
    try {
      const token = localStorage.getItem('token');
      const api = axios.create({
        baseURL: process.env.REACT_APP_DEV_MODE === 'true' ? 'http://localhost:8000' : 'https://backend.app.kita.kids',
        withCredentials: true,
      });

      // Remove facility from order
      const orderResponse = await api.delete(`/backoffice/orders/${orderId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          facility_slug: facility.slug
        }
      });

      // Remove facility from application
      if (facility.application && facility.application.id) {
        await api.delete(`/backoffice/application/${facility.application.id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      }

      // Update the local state
      setOrders(prevOrders => prevOrders.map(order => {
        if (order.id === orderId) {
          const updatedOrder = orderResponse.data.updated_order;
          let updatedFacilityIDs;
          
          try {
            updatedFacilityIDs = JSON.parse(updatedOrder.facilityIDs);
          } catch (error) {
            console.error('Error parsing facilityIDs:', error);
            updatedFacilityIDs = [];
          }

          return {
            ...order,
            ...updatedOrder,
            facilityIDs: updatedFacilityIDs
          };
        }
        return order;
      }));

      // Update the facilities state if needed
      setFacilities(prevFacilities => prevFacilities.filter(f => f.slug !== facility.slug));

      console.log(`Facility '${facility.name}' has been removed from order ${orderId}`);

    } catch (error) {
      console.error('Error removing facility:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      if (!isAuthenticated) return;

      setIsLoading(true);
      setError(null);

      try {
        const token = localStorage.getItem('token');
        const api = axios.create({
          baseURL: process.env.REACT_APP_DEV_MODE === 'true' ? 'http://localhost:8000' : 'https://backend.app.kita.kids',
          withCredentials: true,
        });
        const response = await api.post('/backoffice/orders', {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        if (response.data && Array.isArray(response.data.results)) {
          setOrders(response.data.results);

          if (isEmbedded && embedData && embedData.contact && embedData.contact.email) {
            const matchingOrder = response.data.results.find(order => order.email === embedData.contact.email);
            if (matchingOrder) {
              setSelectedOrder(matchingOrder);
            }
          }
        } else {
          console.error('Received data is not in the expected format:', response.data);
          setError('Received invalid data format from the server.');
          setOrders([]);
        }
      } catch (err) {
        console.error('Error fetching orders:', err);
        setError('Failed to fetch orders. Please try again later.');
        setOrders([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, [isAuthenticated, isEmbedded, embedData]);

  const filteredOrders = useMemo(() => {
    if (!Array.isArray(orders)) return [];
    
    if (isEmbedded && selectedOrder) {
      return [selectedOrder];
    }

    return orders.filter(order => {
      return (
        (filters.paymentStatus === '' || (order.paymentStatus && order.paymentStatus.value === filters.paymentStatus)) &&
        (filters.packet === '' || (order.packet && order.packet.value === filters.packet)) &&
        (filters.active === '' || (order.Active !== undefined && order.Active.toString() === filters.active))
      );
    });
  }, [filters, orders, isEmbedded, selectedOrder]);

  const safeAccess = (obj, path, fallback = '') => {
    return path.split('.').reduce((acc, key) => (acc && acc[key] !== null ? acc[key] : null), obj) ?? fallback;
  };

  return (
    <div className={`max-w-4xl mx-auto ${isEmbedded ? 'mt-0' : 'mt-10'} px-4`}>
      {!isEmbedded && <h2 className="text-3xl font-bold mb-8">Backoffice Dashboard</h2>}
      {isLoading ? (
        <p>Loading orders...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <>
          {!isEmbedded && (
            <>
              <FilterComponent filters={filters} setFilters={setFilters} />
            </>
          )}
          <div className="grid gap-8 md:grid-cols-1">
            <div>
              {!isEmbedded && <h3 className="text-xl font-semibold mb-4">Bestellungen</h3>}
              {filteredOrders.map(order => (
                <OrderItem 
                  key={safeAccess(order, 'id', `order-${Math.random()}`)} 
                  order={order} 
                  safeAccess={safeAccess} 
                  isExpanded={isEmbedded}
                  onRemoveFacility={handleRemoveFacility}
                  updateFacilities={setFacilities}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Backoffice;
