import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const BackendAuthContext = createContext();

export const useBackendAuth = () => useContext(BackendAuthContext);

const api = axios.create({
  baseURL: process.env.REACT_APP_DEV_MODE === 'true' ? 'http://localhost:8000' : 'https://backend.app.kita.kids',
  withCredentials: true,
});

export const BackendAuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showAuthModal, setShowAuthModal] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      checkAuthStatus();
    } else {
      setIsLoading(false);
    }
  }, []);

  const checkAuthStatus = async () => {
    setIsLoading(true);
    const token = localStorage.getItem('token');
    if (!token) {
      setIsAuthenticated(false);
      setShowAuthModal(true);
      setIsLoading(false);
      return;
    }
    try {
      await api.post('/verify', { token });
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Error checking auth status:', error);
      setIsAuthenticated(false);
      setShowAuthModal(true);
      localStorage.removeItem('token');
      delete api.defaults.headers.common['Authorization'];
    } finally {
      setIsLoading(false);
    }
  };

  const login = async (username, password) => {
    try {
      const response = await api.post('/token', new URLSearchParams({
        username,
        password,
        grant_type: 'password'
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      localStorage.setItem('token', response.data.access_token);
      api.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
      setIsAuthenticated(true);
      setShowAuthModal(false);
      await checkAuthStatus();
      return response.data;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    delete api.defaults.headers.common['Authorization'];
    setIsAuthenticated(false);
    setShowAuthModal(true);
  };

  const verifyToken = async (token) => {
    try {
      await api.post('/verify', { token });
      return true;
    } catch (error) {
      console.error('Token verification error:', error);
      return false;
    }
  };

  const value = {
    isAuthenticated,
    isLoading,
    login,
    logout,
    checkAuthStatus,
    verifyToken,
    showAuthModal,
    setShowAuthModal,
  };

  return (
    <BackendAuthContext.Provider value={value}>
      {children}
    </BackendAuthContext.Provider>
  );
};
